import React, { useContext, useEffect, useState } from 'react'
import { Link } from 'react-router-dom'
import axios from 'axios'

import { thousandsSeparators } from '../lib/utils'
import hero_image from '../images/hero_image.jpg'
import SearchFilter from './SearchFilter'
import Nav from './Nav'
import Footer from './Footer'
import ScrollButton from './ScrollButton'
import { makeStyles } from '@material-ui/core/styles'
import Pagination from '@material-ui/lab/Pagination'
import Skeleton from '@material-ui/lab/Skeleton';
import { PropertyTypeSelected, SearchFilterDataContext } from './Context'


const useStyles = makeStyles((theme) => ({
	root: {
		'& > *': {
			marginTop: theme.spacing(2),
		},
	},
}));

const AllProperties = (props) => {


	const limit = 10
	const classes = useStyles()
	const [properties, setProperties] = useState()
	const [loading, setLoading] = useState(true)
	const { propertyTypeSelected, setPropertyTypeSelected } = useContext(PropertyTypeSelected)
	const [page, setPage] = useState(1)
	const { searchFilter } = useContext(SearchFilterDataContext)


	useEffect(() => {
		window.scrollTo(0, 0)
		if (searchFilter) {
			handleClickFilter()
		} else if (propertyTypeSelected) {
			fetchAllProperties()
		} else if (!propertyTypeSelected && !searchFilter) {
			handleClickFilter()
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [])


	const handleClickFilter = (page) => {
		if (window.innerWidth < 767) {
			window.scrollTo(0, 500)
		}

		setPropertyTypeSelected('')
		if (page === undefined) {
			page = 1
		}
		setLoading(true)
		axios.post(`/api/search?page=${page}&limit=${limit}`, searchFilter)
			.then((res) => {
				setProperties(res.data)
				setLoading(false)
			})
			.catch(err => console.error(err))
	}


	const fetchAllProperties = (page) => {
		const propertyType = propertyTypeSelected
		if (window.innerWidth < 767) {
			window.scrollTo(0, 500)
		}
		if (page === undefined) {
			page = 1
		}
		setLoading(true)
		if (propertyTypeSelected) {
			axios.get(`/api/${propertyType}?page=${page}&limit=${limit}`)
				.then((res) => {
					setProperties(res.data)
					setLoading(false)
				})
				.catch((err) => console.error(err))
		}
	}


	function sliceDescription(description) {
		const splitToWords = description.split(' ').splice(0, 30).join(' ')
		return splitToWords
	}


	function propertiesOutOfTheTotal(page) {
		const { totalPages } = properties
		const { totalProperties } = properties

		let result
		if (page === totalPages) {
			result = totalProperties
		} else {
			result = page * limit
		}
		return result
	}


	const handlePagination = (event, page, scrollTop) => {
		setPage(page)
		if (searchFilter) {
			handleClickFilter(page)
		} else {
			fetchAllProperties(page)
		}
		// If bottom pagination, then scroll to top
		if (scrollTop && window.innerWidth < 767) {
			window.scrollTo(0, 500)
		} else if (window.innerWidth > 767) {
			window.scrollTo(0, 0)
		}
	}

	return <div className="all-properties-container">
		<Nav props={props} />
		<div className="properties-search-container">
			<SearchFilter
				handleClickFilter={handleClickFilter}
			/>
		</div>
		{!properties || !properties.totalPages ? null : <div className={`${classes.root} pagination`}>
			<Pagination page={page} count={properties.totalPages} variant="outlined" shape="rounded" onChange={(event, page) => handlePagination(event, page)} />
			<p>Rezultate: {propertiesOutOfTheTotal(page)} din {properties.totalProperties}</p>
		</div>}
		{loading && <div className="loading-animation">
			<div>
				<Skeleton />
				<Skeleton animation={false} />
				<Skeleton animation="wave" />
			</div>
			<div>
				<Skeleton />
				<Skeleton animation={false} />
				<Skeleton animation="wave" />
			</div>

		</div>}

		{(!properties || properties.results.length === 0) && !loading ? <div className="no-properties"><h3>Ne pare rau, nu s-a gasit nicio proprietate care sa corespunda criteriilor de cautare.</h3></div> : null}
		{/* Mobile layout */}
		<div className="is-mobile-card">
			{properties && properties.results.map((property) => {
				return <Link key={property.id} to={`/property/${property.id}`} style={{ textDecoration: "none" }}><div className="property-card">
					<div className="ribbon ribbon-top-right">
						{!property.for_sale ? null : <span>Vanzare</span>}
						{!property.for_rent ? null : <span>Inchiriere</span>}
					</div>
					<h3 className="no-image">Nu sunt imagini disponibile</h3>
					{!property.resized_images[0] ? <div className="image" style={{ backgroundImage: `url(${hero_image})` }}></div> : <div className="image" style={{ backgroundImage: `url(${property.resized_images[0]})` }}>
						<div className="more-details">
							<div>
								<div className="icon sqm"></div>
								{property.surface_built ? <p>{property.surface_built}<sup>m2</sup></p> : <p>{property.surface_land}<sup>m2</sup></p>}
							</div>
							{property.property_type === 1 || property.property_type === 3 ? <div>
								<div className="icon bed"></div>
								<p>{property.rooms}</p>
								<i className="fas fa-bath"></i>
								<p>{property.bathrooms}</p>
							</div> : null}
						</div>
					</div>}
					<div className="content">
						<h3>{property.city}, {property.zone}</h3>
						{!property.price_rent ? null : <h4>{thousandsSeparators(property.price_rent)} €</h4>}
						{!property.price_sale ? null : <h4>{thousandsSeparators(property.price_sale)}€</h4>}
						<h4>{property.title}</h4>
					</div>
				</div></Link>
			})}
		</div>
		{/* Desktop layout */}
		<div className="properties-card-container">
			{properties && properties.results && properties.results.map((property) => {
				return <div key={property.id} className="property-card">
					<div className="ribbon ribbon-top-right">
						{!property.for_sale ? null : <span>Vanzare</span>}
						{!property.for_rent ? null : <span>Inchiriere</span>}
					</div>
					<h3 className="no-image">Nu sunt imagini disponibile</h3>
					{!property.resized_images[0] ? <div className="image" style={{ backgroundImage: `url(${hero_image})` }}></div> : <div className="image" style={{ backgroundImage: `url(${property.resized_images[0]})` }}></div>}
					<div className="content">
						<Link to={`/property/${property.id}`} ><h3>{property.city}, {property.zone}</h3></Link>
						{!property.price_rent ? null : <h4>{thousandsSeparators(property.price_rent)} €</h4>}
						{!property.price_sale ? null : <h4>{thousandsSeparators(property.price_sale)}€</h4>}
						<h4>{property.title}</h4>
						<p>{sliceDescription(property.description)}<Link to={`/property/${property.id}`}><span>...mai multe detalii</span></Link></p>
					</div>
				</div>
			})}
		</div>
		{!properties || !properties.totalPages ? null : <div className={`${classes.root} pagination margin-bottom`}>
			<Pagination page={page} count={properties.totalPages} variant="outlined" shape="rounded" onChange={(event, page) => handlePagination(event, page, 'scrollTop')} />
		</div>}
		<Footer />
		<ScrollButton />
	</div>
}


export default AllProperties