import React from 'react'

import our_mission from '../images/our_mission.jpg'


const OurMission = () => {


	return <div className="our-mission-container" >
		<div className="image-container" style={{ backgroundImage: `url(${our_mission})` }}>
			<div className="overlay">
				<div className="text-container" >
					<h2>Misiunea <span>Noastra</span> </h2>
					<p>Intr-o piata imobiliara precum cea din Cluj-Napoca, care a cunoscut o crestere incredibila in ultimii ani, stim ca sunt necesare serviciile noastre.</p>
					<p>Ne straduim sa oferim servicii de cea mai buna calitate si suntem focusati pe imbunatatirea constanta a acestora.</p>
					<p>Majoritatea citesct cuvantul just in engleza, ii de inteles. Noi il folosim ca si cuvant romanesc, iar principiile care reies din definire acestuia stau la baza companiei noastre.</p>
				</div>
			</div>
		</div>
	</div>
}

export default OurMission