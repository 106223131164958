import emailjs from "emailjs-com"
import React from "react"

import Nav from './Nav'


const ContactForm = (props) => {

	function sendEmail(event) {
		event.preventDefault()
		emailjs.sendForm('mariuszerni90@gmail.com', 'template_j4s3uon', event.target, 'user_oc87UMLMFC4G6ba3uyRpi')
			.then((result) => {
				if (result.text === 'OK') {
					props.history.push('/')

				}
			}, (error) => {
				console.error(error.text)

			})
		event.target.reset()
	}

	return <><Nav props={props} />

		<div className="container-log-reg">
			<div className="container-wrap">
				<form onSubmit={sendEmail}>
					<div className="input">
						<input type="text" name="name" autoComplete="off" required />
						<label htmlFor="name" className="label-name">
							<span className="content-name">Nume</span>
						</label>
					</div>
					<div className="input">
						<input type="email" name="email" autoComplete="off" required />
						<label htmlFor="name" className="label-name">
							<span className="content-name">Email</span>
						</label>
					</div>
					<div className="input">
						<input type="text"
							name="subject" autoComplete="off" required />
						<label htmlFor="name" className="label-name">
							<span className="content-name">Titlu</span>
						</label>
					</div>
					<textarea type="text"
						placeholder="Mesajul tau..." />
					<div className="btn-container">
						<button>Trimite</button>
					</div>
				</form>
			</div>
		</div></>
}

export default ContactForm