import React, { useEffect } from 'react'

import Nav from './Nav'
import Footer from './Footer'
import Carousel from 'react-material-ui-carousel'
import ScrollButton from './ScrollButton'

const testimonials = [["Peter Andrei", "Extraordinar de saritor agentii de la Just. Fie ca era prima ora a diminetii sau seara tarziu daca am avut intrebari am primit raspunsuri. Foarte informative discutiile si bine venite mai ales cand nu stii care e urmatorul pas. M-au ajutat si consultativ referitor la evaluarea pretului , mi-au zis unde e categoria de pret corecta pentru a putea finaliza vanzarea."],
                      ["Roxana Marita", "Recomand cu mare, mare drag Just Imobiliare. Am lucrat direct cu Aris si pot spune ca este profi, prompt in a rezolva diversele situatii si foarte ajutator in a gestiona relatia cu proprietarii. Multumesc mult pentru ajutor! 😊"],
                      ["Liviu Chirvase", "Servicii excelente, ofertele se bazeaza pe cererile si necesitatile clientului astfel ca intr-un timp scurt am reusit sa gasesc apartamentul ideal raportat cerintelor mele! Recomand!"]]


const Buyers = (props) => {

    useEffect(() => {
        window.scrollTo(0, 0)
    }, [])


    return <><Nav props={props}/>
        <div className="clients-container">
            <div className="content-text">
                <h2 className="title">Locatari</h2>
                <p>Daca va ganditi sa va mutati in zona, va rugam sa ne contactati pentru a incepe cautarea. Suntem probabil, singura agentie cu un Comision FIX indiferent de pretul apartamentului.</p>
                <p>Va rugam sa consultati mai jos informatii despre costurile asociate cu inchirierea unei proprietati.</p>
            </div>
            <div className="testimonials">
                <h2>Ce spun clientii nostri</h2>
                <div className="testimonial-slider">
                    <Carousel
                        autoPlay={false}
                        interval={4000}
                        timeout={400}
                        animation="slide"
                        navButtonsAlwaysVisible={false}>
                        {!testimonials ? null : testimonials.map((item, i) => {
                            return <div key={i} className="carousel-item">
                                <h4>{item[0]}</h4>
                                <div>
                                    <i className="fas fa-star"></i>
                                    <i className="fas fa-star"></i>
                                    <i className="fas fa-star"></i>
                                    <i className="fas fa-star"></i>
                                    <i className="fas fa-star"></i>
                                </div>
                                <p>{item[1]}</p>
                            </div>
                        })}
                    </Carousel>
                </div>
            </div>
        </div>
        <Footer />
        <ScrollButton />
    </>
}


export default Buyers