import React from 'react'
import { Link } from 'react-router-dom'

import { thousandsSeparators } from '../lib/utils'


const Card = ({ properties }) => {
	return <>{!properties ? null : properties.map((property) => {
		return <Link to={`/property/${property.id}`} style={{ textDecoration: 'none' }}>
			<div key={property.id} className="card-property"
				style={{ textDecoration: 'none' }}>
				<div className="image-container" style={{ backgroundImage: `url(${property.resized_images[0]})` }} >
					{!property.price_rent ? null : <p>{thousandsSeparators(property.price_rent)} €</p>}
					{!property.price_sale ? null : <p>{thousandsSeparators(property.price_sale)} €</p>}
					{!property.price_rent ? null : <p>Inchiriere</p>}
					{!property.price_sale ? null : <p>Vanzare</p>}
				</div>
				<div className='details-container'>
					<div className="property-details">
						<h4>{property.city}</h4>
						<p>{property.zone}</p>
					</div>
					<div className="more-details">
						<div>
							<div className="icon sqm"></div>
							{property.surface_built ? <p>{property.surface_built}<sup>m2</sup></p> : <p>{property.surface_land}<sup>m2</sup></p>}
						</div>
						{property.property_type === 1 || property.property_type === 3 ? <div>
							<div className="icon bed"></div>
							<p>{property.rooms}</p>
							<i className="fas fa-bath"></i>
							<p>{property.bathrooms}</p>
						</div> : null}
					</div>
				</div>

			</div>
		</Link>

	})}
	</>
}

export default Card