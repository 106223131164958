import React from 'react'


const Svg = () => {
	return <svg xmlns="http://www.w3.org/2000/svg" xmlnsxlink="http://www.w3.org/1999/xlink" width="20" height="20" viewBox="0 0 79.94 79.536">
		<defs>
			<clipPath id="clip-path">
				<rect width="107.653" height="10" fill="none" />
			</clipPath>
		</defs>
		<g id="Group_2" data-name="Group 2" transform="translate(-850.413 -551.114)">
			<g id="Group_1" data-name="Group 1" transform="translate(61 -14)">
				<g id="Repeat_Grid_1" data-name="Repeat Grid 1" transform="translate(789.913 642.736) rotate(-45)" clip-path="url(#clip-path)">
					<g transform="translate(-774.855 -604.382)">
						<path id="Path_7" data-name="Path 7" d="M774.855,605.382h20.653" fill="none" stroke="#000000" stroke-width="10" />
					</g>
					<g transform="translate(-733.855 -604.382)">
						<path id="Path_7-2" data-name="Path 7" d="M774.855,605.382h20.653" fill="none" stroke="#000000" stroke-width="10" />
					</g>
					<g transform="translate(-692.855 -604.382)">
						<path id="Path_7-3" data-name="Path 7" d="M774.855,605.382h20.653" fill="none" stroke="#000000" stroke-width="10" />
					</g>
				</g>
				<path id="Path_8" data-name="Path 8" d="M824.853,566.614h40v40" transform="translate(4 -1)" fill="none" stroke="#000000" stroke-width="10" />
				<path id="Path_9" data-name="Path 9" d="M868.853,613.751V644.39h-78.94V565.854h30.5" transform="translate(0 -0.24)" fill="none" stroke="#000000" stroke-width="10" />
			</g>
		</g>
	</svg>
}


export default Svg