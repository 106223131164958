import React from 'react'


const FeaturedProperties = ({ featuredProperties, props }) => {


	const handleClick = (event, id) => {
		return props.history.push(`/property/${id}`)
	}

	return <div className="featured-properties-container">
		<div className="text-container">
			<h2><span>Featured</span> Properties</h2>
		</div>
		<div className="cards-container">
			{!featuredProperties ? null : featuredProperties.map(property => {
				return <div key={property.id} onClick={event => handleClick(event, property.id)} className="cards">
					<div className="card card1">
						<div className="container" >
							<div className="container-img" style={{ backgroundImage: `url(${property.resized_images[0]})` }}></div>
						</div>
						<div className="details">
							<h3>{property.city}, {property.zone}</h3>
							<p>{property.title}</p>
						</div>
					</div>


				</div>
			})}
		</div>

	</div>
}


export default FeaturedProperties
