import React, { useState, useMemo } from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter, Switch, Route } from 'react-router-dom'

import '../styles/all.scss'

import Home from '../components/Home'
import AllProperties from '../components/AllProperties'
import SingleProperty from '../components/SingleProperty'
import Sellers from '../components/Sellers'
import Buyers from '../components/Buyers'
import Landlords from '../components/Landlords'
import Tenants from '../components/Tenants'
import AboutUs from '../components/AboutUs'
import ContactForm from '../components/ContactForm'
import { AllPropertiesContext, PropertyTypeSelected, SearchFilterDataContext } from '../components/Context'


const App = () => {

	const [searchFilter, setSearchFilter] = useState({
		location: '',
		transactionType: '',
		priceRange: {
			min: null,
			max: null
		},
		sqMeters: {
			min: null,
			max: null
		},
		propertyType: '',
		rooms: ''
	})

	const [properties, setProperties] = useState(null)
	const [propertyTypeSelected, setPropertyTypeSelected] = useState("properties")

	const searchFilterValue = useMemo(() => ({ searchFilter, setSearchFilter }), [searchFilter, setSearchFilter])
	const allPropertiesValue = useMemo(() => ({ properties, setProperties }), [properties, setProperties])
	const propertiesTypeSelectedValue = useMemo(() => ({ propertyTypeSelected, setPropertyTypeSelected }), [propertyTypeSelected, setPropertyTypeSelected])

	return (<BrowserRouter>
		<Switch>
			<AllPropertiesContext.Provider value={allPropertiesValue}>
				<PropertyTypeSelected.Provider value={propertiesTypeSelectedValue}>
					<SearchFilterDataContext.Provider value={searchFilterValue}>
						<Route exact path="/" component={Home} />
						<Route path="/properties" component={AllProperties} />
						<Route path="/property/:id" component={SingleProperty} />
						<Route path="/sellers" component={Sellers} />
						<Route path="/buyers" component={Buyers} />
						<Route path="/landlords" component={Landlords} />
						<Route path="/tenants" component={Tenants} />
						<Route path="/contact" component={ContactForm} />
						<Route path="/about" component={AboutUs} />
					</SearchFilterDataContext.Provider>

				</PropertyTypeSelected.Provider>
			</AllPropertiesContext.Provider>
		</Switch>
	</BrowserRouter>)
}



ReactDOM.render(<App />, document.getElementById('root'))
