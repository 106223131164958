import React, { useEffect, useState } from 'react'
import { Link } from 'react-router-dom'




const Footer = () => {

	const [currentYear, setCurrentYear] = useState()
	const date = new Date()

	useEffect(() => {
		setCurrentYear(date.getFullYear())
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [])



	return <div className="full-screen">
		<div className="footer-container">
			<div className="social-media-footer">
				<Link to="//facebook.com/JustAdministrare/" target="_blank">
					<div className="icon">
						<i className="fab fa-facebook-f"></i>
					</div>
				</Link>
				<Link to="//linkedin.com/in/aris-stan-109079131/" target="_blank">
					<div className="icon">
						<i className="fab fa-linkedin-in"></i>
					</div>
				</Link>
			</div>

			<div className="links-container">
				<h3>Links</h3>
				
				<Link to="/properties" style={{ textDecoration: 'none' }} ><h4>Proprietati</h4></Link>
				<Link to="/buyers" style={{ textDecoration: 'none' }} ><h4>Cumparatori</h4></Link>
				<Link to="about" style={{ textDecoration: 'none' }} ><h4>Despre noi</h4></Link>
				<Link to="/sellers" style={{ textDecoration: 'none' }} ><h4>Vanzatori</h4></Link>
				<Link to="/landlords" style={{ textDecoration: 'none' }} ><h4>Proprietari</h4></Link>
				<Link to="//justadministrare.ro/" target="_blank" style={{ textDecoration: 'none' }} ><h4>Administrare</h4></Link>
				<Link to="/tenants" style={{ textDecoration: 'none' }} ><h4>Locatari</h4></Link>
				<Link to="/contact" style={{ textDecoration: 'none' }} ><h4>Contact</h4></Link>
			</div>
		</div>
		<div className="bottom-footer">
			<p>&#169; {currentYear} Just Imobiliare</p>
		</div>
	</div>
}

export default Footer