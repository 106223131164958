import { createContext } from 'react'

export const AllPropertiesContext = createContext(null)
export const PropertyTypeSelected = createContext()
export const SearchFilterDataContext = createContext(
    {
        location: '',
        transactionType: '',
        priceRange: {
            min: null,
            max: null
        },
        sqMeters: {
            min: null,
            max: null
        },
        propertyType: '',
        rooms: ''
    }
)