import React, { useEffect } from 'react'
import { Link } from 'react-router-dom'

import Nav from './Nav'
import Footer from './Footer'
import Carousel from 'react-material-ui-carousel'
import ScrollButton from './ScrollButton'

const testimonials = [["Peter Andrei", "Extraordinar de saritor agentii de la Just. Fie ca era prima ora a diminetii sau seara tarziu daca am avut intrebari am primit raspunsuri. Foarte informative discutiile si bine venite mai ales cand nu stii care e urmatorul pas. M-au ajutat si consultativ referitor la evaluarea pretului , mi-au zis unde e categoria de pret corecta pentru a putea finaliza vanzarea."],
["Roxana Marita", "Recomand cu mare, mare drag Just Imobiliare. Am lucrat direct cu Aris si pot spune ca este profi, prompt in a rezolva diversele situatii si foarte ajutator in a gestiona relatia cu proprietarii. Multumesc mult pentru ajutor! 😊"],
["Liviu Chirvase", "Servicii excelente, ofertele se bazeaza pe cererile si necesitatile clientului astfel ca intr-un timp scurt am reusit sa gasesc apartamentul ideal raportat cerintelor mele! Recomand!"]]


const Buyers = (props) => {

	useEffect(() => {
		window.scrollTo(0, 0)
	}, [])


	return <><Nav props={props} />
		<div className="clients-container">
			<div className="content-text">
				<h2 className="title">Proprietari</h2>
				<p>Gasirea celor mai buni chiriasi.</p>
				<p>Recunoastem ca cerintele dvs. ca proprietar sunt la fel de individuale ca si locuinta pe care o inchiriati, indiferent daca aveti o investitie imobiliara sau un intreg portofoliu. Personalul nostru are cunostinte extinse despre piata locala si cum sa va gaseasca cei mai buni chiriasi la cel mai bun pret.</p>
				<p>Mai mult, ne place sa lucram in parteneriat cu proprietarii nostri si ne putem ocupa noi de administrarea apartamentului, pentru mai multe detalii accesati site-ul: <Link to="//justadministrare.ro/" target="_blank" style={{ textDecoration: 'none', color: 'blue' }} >www.justadministrare.ro</Link>.</p>
				<p>Deoarece primele impresii sunt vitale, va putem ajuta sa va asigurati ca prezentarea proprietatii dvs. este perfecta. Daca este necesar, colaboratorii nostri in proiectare va pot ajuta cu orice, de la o reimprospatare minora la o renovare completa.</p>
				<p>Vom lucra neobosit pentru a identifica chiriasul ideal la pretul ideal. Dupa ce a fost gasit un chirias adecvat, vom lucra rapid, obtinand referinte si tratand conformitatea si legislatia, pentru a ne asigura ca mutarea se desfasoara fara probleme.</p>
				<p>Daca doriti o evaluare a proprietatii dvs. sau doar o actualizare pe piata locala, va rugam sa ne contactati.</p>
			</div>
			<div className="testimonials">
				<h2>Ce spun clientii nostri</h2>
				<div className="testimonial-slider">
					<Carousel
						autoPlay={false}
						interval={4000}
						timeout={400}
						animation="slide"
						navButtonsAlwaysVisible={false}>
						{!testimonials ? null : testimonials.map((item, i) => {
							return <div key={i} className="carousel-item">
								<h4>{item[0]}</h4>
								<div>
									<i className="fas fa-star"></i>
									<i className="fas fa-star"></i>
									<i className="fas fa-star"></i>
									<i className="fas fa-star"></i>
									<i className="fas fa-star"></i>
								</div>
								<p>{item[1]}</p>
							</div>
						})}
					</Carousel>
				</div>
			</div>
		</div>
		<Footer />
		<ScrollButton />
	</>
}


export default Buyers