import React, { useState, useEffect, useContext } from 'react'
import axios from 'axios'

import Nav from './Nav'
import FeaturedProperties from './FeaturedProperties'
import OurMission from './OurMission'
import AdvertisedProperties from './AdvertisedProperties'
import Agents from './Agents'
import Footer from './Footer'
import SearchFilter from './SearchFilter'
import ScrollButton from './ScrollButton'
import { SearchFilterDataContext } from './Context'
import hero_image from '../images/hero_image.jpg'
import Alert from '@material-ui/lab/Alert';
import IconButton from '@material-ui/core/IconButton';
import Collapse from '@material-ui/core/Collapse';
import CloseIcon from '@material-ui/icons/Close';


const Home = (props) => {


	const [featuredProperties, setFeaturedProperties] = useState()
	const [advertisedProperties, setAdvertisedProperties] = useState()
	const { searchFilter, setSearchFilter } = useContext(SearchFilterDataContext)

	const [errors, setErrors] = useState()


	const fetchFeaturedProperties = () => {
		axios.get('/api/featured')
			.then((res) => {
				setFeaturedProperties(res.data)
			})
			.catch(err => console.error(err))
	}


	const fetchAdvertisedProperties = () => {
		axios.get('/api/advertised')
			.then((res) => {
				setAdvertisedProperties(res.data)
			}
			)
			.catch(err => console.error(err))
	}


	useEffect(() => {
		window.scrollTo(0, 0)
		setSearchFilter('')
		fetchFeaturedProperties()
		fetchAdvertisedProperties()
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [])

	const handleClickFilter = () => {
		if (searchFilter) {
			props.history.push('/properties')
		} else {
			setErrors('Nu au fost introduse filtre de cautare!')
		}
	}


	return <div className="main-container">
		<Nav props={props} />
		<div className="hero-container"
			style={{ backgroundImage: `url(${hero_image})` }}>
			<h1 className="vertical-text">Just</h1>
			<div className="image-overlay">
				<div className="information">
					<h1 className="">Pentru mai multe informatii</h1>
					<h4>Contacteaza-ne la numarul de telefon de mai jos</h4>
					<div className="phone-number-container">
						<i className="fas fa-phone-alt"></i>
						<a className="phone-link" href="tel: +40737 772 784"><h2>0737 772 784</h2></a>
					</div>
				</div>
			</div>
			{errors && <div className="alert">
				<Collapse in={errors}>
					<Alert
						severity="warning"
						action={
							<IconButton
								aria-label="close"
								color="inherit"
								size="small"
								onClick={() => {
									setErrors('')
								}}>
								<CloseIcon fontSize="inherit" />
							</IconButton>
						}>
						{errors}
					</Alert>
				</Collapse>
			</div>}
			<SearchFilter
				handleClickFilter={handleClickFilter}
				props={props} />
		</div>
		<FeaturedProperties props={props} featuredProperties={featuredProperties} />
		<OurMission />
		<AdvertisedProperties props={props} advertisedProperties={advertisedProperties} />
		{/* <Agents /> */}
		<Footer />
		<ScrollButton />
	</div>
}

export default Home
