import React, { useEffect } from 'react'

import Nav from './Nav'
import Footer from './Footer'
import Carousel from 'react-material-ui-carousel'
import ScrollButton from './ScrollButton'

const testimonials = [["Peter Andrei", "Extraordinar de saritor agentii de la Just. Fie ca era prima ora a diminetii sau seara tarziu daca am avut intrebari am primit raspunsuri. Foarte informative discutiile si bine venite mai ales cand nu stii care e urmatorul pas. M-au ajutat si consultativ referitor la evaluarea pretului , mi-au zis unde e categoria de pret corecta pentru a putea finaliza vanzarea."],
["Roxana Marita", "Recomand cu mare, mare drag Just Imobiliare. Am lucrat direct cu Aris si pot spune ca este profi, prompt in a rezolva diversele situatii si foarte ajutator in a gestiona relatia cu proprietarii. Multumesc mult pentru ajutor! 😊"],
["Liviu Chirvase", "Servicii excelente, ofertele se bazeaza pe cererile si necesitatile clientului astfel ca intr-un timp scurt am reusit sa gasesc apartamentul ideal raportat cerintelor mele! Recomand!"]]

const Sellers = (props) => {

	useEffect(() => {
		window.scrollTo(0, 0)
	}, [])

	return <><Nav props={props} />
		<div className="clients-container">
			<div className="content-text">
				<h2 className="title">Vanzatori</h2>
				<p>Listati-va proprietatea!</p>
				<p>Ne oferim cu drag serviciile avand cunostintele necesare si experienta pe piata locala in ceea ce priveste tipurile de proprietati si cum sa gasim cei mai buni cumparatori.</p>
				<p>Recunoastem ca cerintele dvs. de vanzator sunt la fel de individuale ca si locuinta pe care o vindeti, asa ca vom crea o campanie de marketing adaptata, conceputa pentru a testa intreaga piata si pentru a obtine cel mai bun pret.</p>
				<p>Estimam ca petrecem pana la 200 de ore de lucru la fiecare vanzare. Tot acest marketing proactiv este conceput pentru a genera un interes si vizionari extraordinare atunci cand proprietatea dvs. este lansata pe piata. Vom insoti cumparatorii la vizionarea programarilor pentru a arata cel mai bine caracteristicile proprietatii dvs. si pentru a evidentia atractiile din zona locala. Va vom tine la curent cu gandurile si comentariile spectatorilor, deoarece este importatnt sa stiti cum merge promovarea. Odata ce am gasit cumparatorul si o oferta este ecceptata, vom lucra din greu pentru a ne asigura ca vanzarea progreseaza in timp util, legandu-ne cu avocatii din ambele parti, topografii, consilierii financiari sau alti profesionisti implicati in tranzactie. Va vom tine pe deplin informat pe tot parcursul procesului pentru a ne asigura ca stiti ce facem si ce se va intampla in continuare. Daca doriti o evaluare a proprietatii dvs. sau doar o actualizare pe piata locala, va rugam sa ne contactati.</p>


			</div>
			<div className="testimonials">
				<h2>Ce spun clientii nostri</h2>
				<div className="testimonial-slider">
					<Carousel
						autoPlay={false}
						interval={4000}
						timeout={400}
						animation="slide"
						navButtonsAlwaysVisible={false}>
						{!testimonials ? null : testimonials.map((item, i) => {
							return <div key={i} className="carousel-item">
								<h4>{item[0]}</h4>
								<div>
									<i className="fas fa-star"></i>
									<i className="fas fa-star"></i>
									<i className="fas fa-star"></i>
									<i className="fas fa-star"></i>
									<i className="fas fa-star"></i>
								</div>
								<p>{item[1]}</p>
							</div>
						})}
					</Carousel>
				</div>

			</div>
		</div>
		<Footer />
		<ScrollButton />
	</>
}


export default Sellers