import React, { useState, useEffect, useContext } from 'react'
import axios from 'axios'
import { SearchFilterDataContext } from './Context'

import Alert from '@material-ui/lab/Alert';
import IconButton from '@material-ui/core/IconButton';
import Collapse from '@material-ui/core/Collapse';
import CloseIcon from '@material-ui/icons/Close';
import { ButtonContained } from './Buttons'

import { thousandsSeparators } from '../lib/utils'

const noLocations = ["Nu sunt localitati disponibile"]
const rooms = ['1', '2', '3', '4']
const propertyType = ['Apartament', 'Casa / Vila', 'Teren', 'Spatiu de birouri', 'Spatiu comercial', 'Spatiu industrial']
const transactionType = ['Inchiriere', 'Vanzare']


const SearchFilter = ({ handleClickFilter }) => {


	const [location, setLocation] = useState()
	const [priceRangeMin, setPriceRangeMin] = useState()
	const [priceRangeMax, setPriceRangeMax] = useState()
	const [sqMetersMin, setSqMetersMin] = useState()
	const [sqMetersMax, setSqMetersMax] = useState()
	const [errors, setErrors] = useState()
	const { searchFilter, setSearchFilter } = useContext(SearchFilterDataContext)


	const handleChange = (name, minMax, event) => {

		const value = event.target.value

		// Setting values for search filter state
		if (name === 'priceRange' || name === 'sqMeters') {
			let newValue = value
			if (value.split('').includes(',')) {
				newValue = value.split(',').join('')
			}
			if (isNaN(newValue)) {
				if (name === 'priceRange') {
					if (minMax === 'min') {
						setPriceRangeMin('')
					} else if (minMax === 'max') {
						setPriceRangeMax('')
					}
				} else if (name === 'sqMeters') {
					if (minMax === 'min') {
						setSqMetersMin('')
					} else if (minMax === 'max') {
						setSqMetersMax('')
					}
				}
			
				return setErrors('Va rugam sa introduceti un numar')
			}

			const result = parseInt(newValue)

			setSearchFilter(prevState => ({
				...prevState, [name]: { ...prevState[name], [minMax]: result }
			}))

			// Setting values for local state
			if (name === 'sqMeters') {
				if (minMax === 'min') {
					setSqMetersMin(thousandsSeparators(newValue))
				} else if (minMax === 'max') {
					setSqMetersMax(thousandsSeparators(newValue))
				}
			} else if (name === 'priceRange') {
				if (minMax === 'min') {
					setPriceRangeMin(thousandsSeparators(newValue))
				} else if (minMax === 'max') {
					setPriceRangeMax(thousandsSeparators(newValue))
				}
			}
		} else {
			setSearchFilter(prevState => ({
				...prevState, [name]: value
			}))
		}
	}

	const handleReset = (searchInput, minMax) => {
		if (searchInput === 'location') {
			setSearchFilter(prevState => ({
				...prevState, location: ''
			}))
		} else if (searchInput === 'transactionType') {
			setSearchFilter(prevState => ({
				...prevState, transactionType: ''
			}))
		} else if (searchInput === 'priceRange' && minMax) {
			// Reseting values local state
			if (minMax === 'min') {
				setPriceRangeMin('')
			} else if (minMax === 'max') {
				setPriceRangeMax('')
			}
			// Reseting values search filter state
			setSearchFilter(prevState => ({
				...prevState, [searchInput]: { ...prevState[searchInput], [minMax]: '' }
			}))
		} else if (searchInput === 'sqMeters' && minMax) {
			// Reseting values local state
			if (minMax === 'min') {
				setSqMetersMin('')
			} else if (minMax === 'max') {
				setSqMetersMax('')
			}
			// Reseting values search filter state
			setSearchFilter(prevState => ({
				...prevState, [searchInput]: { ...prevState[searchInput], [minMax]: '' }
			}))
		} else if (searchInput === 'propertyType') {
			setSearchFilter(prevState => ({
				...prevState, propertyType: ''
			}))
		} else if (searchInput === 'rooms') {
			setSearchFilter(prevState => ({
				...prevState, rooms: ''
			}))
		}
	}

	useEffect(() => {
		fetchPropertiesLocation()
	}, [])


	const fetchPropertiesLocation = () => {
		axios.get('/api/location')
			.then((res) => setLocation(res.data))
			.catch(err => console.error(err))
	}



	return <div className="container-max-w ">
		{errors && <div className="alert">
			<Collapse in={errors}>
				<Alert
					severity="warning"
					action={
						<IconButton
							aria-label="close"
							color="inherit"
							size="small"
							onClick={() => {
								setErrors('')
							}}>
							<CloseIcon fontSize="inherit" />
						</IconButton>
					}>
					{errors}
				</Alert>
			</Collapse>
		</div>}
		<form className="search-container">
			<div className="input-container">
				<div className="dropdown">

					<select className='select' onChange={event => handleChange("location", '', event)} value={searchFilter.location}>
						<option value="" disabled selected hidden>Alege Localitatea</option>
						<option value="" disabled >Toate</option>

						{!location ? noLocations : location.map((property, i) => <option key={i} value={property}>{property}</option>)}
					</select>
					{searchFilter.location ? <p className="reset" onClick={() => handleReset('location')}>x</p> : null}
					<i class="fas fa-chevron-down select-arrow"></i>

				</div>
				<div className="dropdown">
					<select className='select' onChange={event => handleChange("transactionType", '', event)} value={searchFilter.transactionType}>
						<option value="" disabled selected hidden>Tip Tranzactie</option>
						<option value="" disabled >Toate</option>
						{transactionType.map((property, i) => <option key={i} value={property}>{property}</option>)}
					</select>
					{searchFilter.transactionType ? <p className="reset" onClick={() => handleReset('transactionType')}>x</p> : null}
					<i class="fas fa-chevron-down select-arrow"></i>
				</div>

			</div>
			<div className="input-container">
				<div className="flex-container input-mb">
					<div className="input-field">
						<p className="absolute">Pret</p>
						<input inputmode="numeric" onChange={event => handleChange("priceRange", 'min', event)} 
							placeholder="Min" 
							value={priceRangeMin}></input>
						{priceRangeMin ? <p className="reset-input" onClick={() => handleReset('priceRange', 'min')}>x</p> : null}
					</div>
					<div className="input-field">
						<input inputmode="numeric" onChange={event => handleChange("priceRange", 'max', event)} placeholder="Max"
							value={priceRangeMax}></input>
						{priceRangeMax ? <p className="reset-input" onClick={() => handleReset('priceRange', 'max')}>x</p> : null}
					</div>

				</div>
				<div className="flex-container">
					<div className="input-field">
						<p className="absolute">Suprafata (mp)</p>
						<input inputmode="numeric" onChange={event => handleChange("sqMeters", 'min', event)}
							placeholder="Min"
							value={sqMetersMin}></input>
						{sqMetersMin ? <p className="reset-input" onClick={() => handleReset('sqMeters', 'min')}>x</p> : null}
					</div>
					<div className="input-field">
						<input inputmode="numeric" onChange={event => handleChange("sqMeters", 'max', event)} placeholder="Max"
							value={sqMetersMax}></input>
						{sqMetersMax ? <p className="reset-input" onClick={() => handleReset('sqMeters', 'max')}>x</p> : null}
					</div>
				</div>

			</div>
			<div className="input-container">
				<div className="dropdown">
					<select className='select' onChange={event => handleChange("propertyType", '', event)} value={searchFilter.propertyType}>
						<option value="" disabled selected hidden>Tip Proprietate</option>
						<option value="" disabled >Toate</option>
						{propertyType.map((property, i) => <option key={i} value={property}>{property}</option>)}
					</select>
					{searchFilter.propertyType ? <p className="reset" onClick={() => handleReset('propertyType')}>x</p> : null}
					<i class="fas fa-chevron-down select-arrow"></i>
				</div>

				<div className="input-btn dropdown-last">
					<div className="dropdown w-full">
						<select className='select' type="number" onChange={event => handleChange("rooms", '', event)} value={searchFilter.rooms}>
							<option value="" disabled selected hidden>Nr.camere</option>
							<option value="" disabled >Toate</option>
							{rooms.map((property, i) => <option key={i} value={property}>{property}</option>)}
						</select>
						{searchFilter.rooms ? <p className="reset" onClick={() => handleReset('rooms')}>x</p> : null}
						<i class="fas fa-chevron-down select-arrow"></i>
					</div>
					<div className="btn" onClick={() => handleClickFilter()}>
						<ButtonContained buttonTitle="Cauta"></ButtonContained>
					</div>
				</div>
			</div>
		</form>
	</div>
}


export default SearchFilter