export function officePartitioningConvert(officePartitioning) {
  if (officePartitioning === 1) {
    return "Open space"
  } else if (officePartitioning === 2) {
    return "Compartimentat"
  } else if (officePartitioning === 3) {
    return "Partial compartimentat"
  } else if (officePartitioning === 4) {
    return "Flexibil"
  }
}


export function partitioningConvert(partitioning) {
  if (partitioning === 1) {
    return "Decomandat"
  } else if (partitioning === 2) {
    return "Semidecomandat"
  } else if (partitioning === 3) {
    return "Nedecomandat"
  } else if (partitioning === 4) {
    return "Circular"
  } else if (partitioning === 5) {
    return "Vagon"
  }
}

export function interiorStateConvert(interiorState) {
  if (interiorState === 1) {
    return "Ultrafinisat"
  } else if (interiorState === 2) {
    return "Finisat modern"
  } else if (interiorState === 3) {
    return "Semifinisat"
  } else if (interiorState === 4) {
    return "Finisat clasic"
  } else if (interiorState === 5) {
    return "Buna"
  } else if (interiorState === 6) {
    return "Nou"
  } else if (interiorState === 7) {
    return "Renovat"
  } else if (interiorState === 8) {
    return "Necesita renovare"
  }
}

export function buildingType(buildingType) {
  if (buildingType === 1) {
    return "Bloc de Apartamente"
  } else if (buildingType === 2) {
    return "Cladire de birouri"
  } else if (buildingType === 3) {
    return "Casa / Vila"
  } else if (buildingType === 4) {
    return "Hala"
  } else if (buildingType === 5) {
    return "Centru comercial"
  } else if (buildingType === 6) {
    return "Depozit"
  } else if (buildingType === 7) {
    return "Stradal"
  }
}

export function constructionStatus(constructionStatus) {
  if (constructionStatus === 1) {
    return "Finalizata"
  } else if (constructionStatus === 2) {
    return "Finisat"
  } else if (constructionStatus === 3) {
    return "Semifinisat"
  } else if (constructionStatus === 4) {
    return "In constructie"
  } else if (constructionStatus === 5) {
    return "La rosu"
  } else if (constructionStatus === 6) {
    return "Necesita renovare"
  } else if (constructionStatus === 7) {
    return "De demolat"
  } else if (constructionStatus === 8) {
    return "Structura"
  } else if (constructionStatus === 9) {
    return "La gri"
  } else if (constructionStatus === 10) {
    return "Proiect"
  }
}


export function thousandsSeparators(num) {
  
  // const price = num.toString().replace(/\./g, '')
  const price = Math.floor(num)
  
  const result = price.toString().split('.')
  result[0] = result[0].replace(/\B(?=(\d{3})+(?!\d))/g, ',')
  return result.join('')
}


 