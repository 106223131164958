import React from "react";
import Nav from "./Nav";
import Footer from "./Footer";

const AboutUs = (props) => {
    return (
        <>
            <Nav props={props} />
            <div className="clients-container">
                <div className="team-container">
                    <div className="team-title">
                        <h3>Cunoaste Echipa</h3>
                    </div>
                    <div className="agents-container">
                        <div className="team-agent">

                            <h3>Aris Stan</h3>
                            <p className="position">Manager General</p>
                            <p className="phone-number">
                                <i className="fas fa-phone-alt"></i><a className="phone-link" href="tel:0737772784">0737772784</a>
                            </p>
                            <p className="email">
                                <i className="fas fa-envelope"></i>
                                <a className="phone-link" href="mailto:stefan@just-imobiliare.ro">aris@just-imobiliare.ro</a>
                            </p>
                        </div>
                    </div>
                </div>

                <div className="content-text">
                    <h2 className="title">Despre Just Imobiliare</h2>
                    <p>
                        Consideram ca serviciile de intermediere si consultanta imobiliara
                        sunt necesare intr-o piata dinamica, diversificata si in continua
                        crestere precum cea din Cluj-Napoca. Pentru o decizie sanatoasa in
                        ceea ce priveste o achizitionare/vanzare sau inchiriere recomandam o
                        documentare cat mai amanuntita, dar de cele mai multe ori, din
                        pacate, nu avem uneltele si timpul necesar. Acesta este unul dintre
                        motivele pentru care sunt necesare serviciile unei agentii
                        imobiliare.
                    </p>
                </div>
                <div className="content-container mb">
                    <div className="content-about">
                        <h2 className="title">Promovare Si Marketing</h2>
                        <p>
                            In plus, ne focusam pe promovarea proprietatilor colaborand cu
                            principalele site-uri de promovare din tara cat si cu celelalte
                            agentii!
                        </p>
                    </div>
                </div>
            </div>
            <Footer />
        </>
    );
};

export default AboutUs;
