import React, { useState } from 'react'

import ExpandLessIcon from '@material-ui/icons/ExpandLess';
import { red } from '@material-ui/core/colors';




const ScrollButton = () => {
	const [scrollPosition, setScrollPosition] = useState(0)

	window.addEventListener('scroll', () => {
		setScrollPosition(window.scrollY)
	})

	const scrollToTop = () => {
		window.scroll(0, 0)
	}

	return <div className={scrollPosition === 0 ? 'scrolled-top' : 'scroll'}
		onClick={() => { scrollToTop() }}>
		<ExpandLessIcon fontSize="large" style={{ color: red[50] }} />
	</div>

}



export default ScrollButton