import React, { useEffect, useState } from 'react'
import { useHistory } from 'react-router-dom'
import { Link } from 'react-router-dom'
import Carousel from 'react-material-ui-carousel'

import { officePartitioningConvert, partitioningConvert, interiorStateConvert, buildingType, constructionStatus } from '../lib/utils'

import { thousandsSeparators } from '../lib/utils'
import axios from 'axios'
import Nav from './Nav'
import Svg from './Svg'
import Footer from './Footer'
import ScrollButton from './ScrollButton'
import ArrowBackIcon from '@material-ui/icons/ArrowBack';



const SingleProperty = (props) => {

	const [propertyById, setPropertyById] = useState()
	const [recommendedProperties, setRecommendedProperties] = useState()

	let history = useHistory()

	useEffect(() => {
		window.scrollTo(0, 0)
		fetchSingleProperty()
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [])

	useEffect(() => {
		window.scrollTo(0, 0)
		if (propertyById) {
			fetchRecommendedProperties()
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [propertyById])


	const handleClick = (event, id) => {
		event.preventDefault()
		fetchSingleProperty(id)
	}


	const fetchSingleProperty = (recommendedById) => {
		let id
		if (recommendedById) {
			id = recommendedById
		} else {
			id = props.match.params.id
		}
		axios.get(`/api/property/${id}`)
			.then((res) => {
				setPropertyById(res.data[0])
			})
			.catch((err) => console.error(err))
	}


	const fetchRecommendedProperties = () => {
		const recommendedPropertiesFields = {
			city: propertyById.city,
			for_sale: propertyById.for_sale,
			for_rent: propertyById.for_rent,
			property_type: propertyById.property_type,
			id: propertyById.id
		}
		axios.post('/api/recommended-properties', recommendedPropertiesFields)
			.then((res) => {
				setRecommendedProperties(res.data)
			})
			.catch((err) => console.error(err))
	}


	return <><Nav props={props} />
		<div className="single-property-container">
			<div className="button-back"
				onClick={() => history.goBack()}>
				<ArrowBackIcon />
				<p>Inapoi</p>
			</div>
			{!propertyById ? null : <>
				<div className="carousel">  <Carousel
					autoPlay={false}
					interval={4000}
					timeout={400}
					animation="fade"
					navButtonsAlwaysVisible={true}>
					{!propertyById ? null : propertyById.resized_images.map((item, i) => <div key={i} className="image-carousel" style={{ backgroundImage: `url(${item})` }}></div>)}
				</Carousel>
				</div>
				<div className="content">
					<h4>{propertyById.title}</h4>
					<h3>Caracteristici</h3>
					<div className="container-characteristics one">
						<div className="sq-meters">
							<Svg />
							{!propertyById.surface_useable ? null : <p>{propertyById.surface_useable}<sup>m2</sup></p>}
							{!propertyById.surface_land ? null : <p>{propertyById.surface_land}<sup>m2</sup></p>}
						</div>
						<div className="bedrooms">
							<i className="fas fa-bed"></i>
							<p>{propertyById.rooms}</p>
						</div>
						<div className="bathrooms">
							<i className="fas fa-bath"></i>
							<p>{propertyById.bathrooms}</p>
						</div>
					</div>
					<div className="container-characteristics two">
						<p>Etaj: <span>{propertyById.verbose_floor}</span></p>
						{!propertyById.parking_spots ? null : <p>Locuri de parcare: <span>{propertyById.parking_spots}</span></p>}
						{!propertyById.building_construction_year ? null : <p>Anul constructiei: <span>{propertyById.building_construction_year}</span></p>}
					</div>
					<div className="container-characteristics three">
						{!propertyById.partitioning ? null : <p>Tip compartimentare: <span>{partitioningConvert(propertyById.partitioning)}</span></p>}
						{!propertyById.office_partitioning ? null : <p>Tip compartimentare: <span>{officePartitioningConvert(propertyById.office_partitioning)}</span></p>}
						{!propertyById.commercial_building_type ? null : <p>Tip imobil: <span>{buildingType(propertyById.commercial_building_type)}</span></p>}
						{!propertyById.interior_state ? null : <p>Tip finisaj: <span>{interiorStateConvert(propertyById.interior_state)}</span></p>}
						{!propertyById.building_structure ? null : <p>Stadiu de constructie: <span>{constructionStatus(propertyById.building_structure)}</span></p>}
					</div>
					<div className="container-characteristics four">
						{!propertyById.zone ? null : <p>Zona: <span>{propertyById.zone}</span></p>}
						{!propertyById.price_sqm_sale ? null : <p>Pret/mp: <span>{thousandsSeparators(propertyById.price_sqm_sale)} €</span></p>}
						{!propertyById.city ? null : <p>Oras: <span>{propertyById.city}</span></p>}
					</div>
					<div className="container-characteristics five">
						{!propertyById.price_sale ? null : <p>{thousandsSeparators(propertyById.price_sale)} €</p>}
						{!propertyById.price_rent ? null : <p>{thousandsSeparators(propertyById.price_rent)} €</p>}
					</div>
				</div></>}
		</div>
		<div className="description-contact">
			<div className="description-single-property">
				<h3>Descriere oferta</h3>
				{!propertyById ? null : <p>{propertyById.description}</p>}
			</div>
			<div className="contact-details">
				<h3>Interesat de aceasta proprietate?</h3>
				<h4><span>Suna-ne la</span> <a className="email-link" href="tel: 0737 772 784">0737 772 784</a></h4>
				<p>Social media</p>
				<div className="social-media">
					<Link className="email-link" to="//facebook.com/JustAdministrare/" target="_blank"><i className="fab fa-facebook-f"></i></Link>
					<a className="email-link" href="mailto:aris@just-imobiliare.ro"><i className="far fa-envelope"></i></a>
					<Link className="email-link" to="//linkedin.com/in/aris-stan-109079131/" target="_blank"><i className="fab fa-linkedin-in"></i></Link>
				</div>
			</div>
		</div>
		{/* Recommended properties section */}
		{!recommendedProperties || recommendedProperties.length === 0 ? null : <div className="recommendation-section">
			<h3>Oferte similare</h3>
			{/* Mobie layout */}
			<div className="is-mobile">
				<Carousel
					autoPlay={false}
					interval={4000}
					timeout={400}
					animation="slide"
					navButtonsAlwaysVisible={true}>
					{!recommendedProperties ? null : recommendedProperties.map((property) => {
						return <div key={property.id} className="card-property" onClick={event => handleClick(event, property.id)}>
							<div className="image-container" style={{ backgroundImage: `url(${property.resized_images[0]})` }} >
								{!property.price_rent ? null : <p>{thousandsSeparators(property.price_rent)} €</p>}
								{!property.price_sale ? null : <p>{thousandsSeparators(property.price_sale)} €</p>}
								{!property.price_rent ? null : <p>Inchiriere</p>}
								{!property.price_sale ? null : <p>Vanzare</p>}
							</div>
							<div className="property-details">
								<h4>{property.city}</h4>
								<p>{property.zone}</p>
							</div>
							<div className="more-details">
								<div>
									<div className="icon sqm"></div>
									{property.surface_useable ? <p>{property.surface_useable}<sup>m2</sup></p> : <p>{property.surface_land}<sup>m2</sup></p>}
								</div>
								{property.property_type === 1 || property.property_type === 3 ? <div>
									<div className="icon bed"></div>
									<p>{property.rooms}</p>
									<i className="fas fa-bath"></i>
									<p>{property.bathrooms}</p>
								</div> : null}
							</div>
						</div>
					})}
				</Carousel>
			</div>
			{/* Desktop layout */}
			<div className="properties-wrapper">
				{!recommendedProperties ? null : recommendedProperties.map((property) => {
					return <div key={property.id} className="card-property" onClick={event => handleClick(event, property.id)}>
						<div className="image-container" style={{ backgroundImage: `url(${property.resized_images[0]})` }} >
							{!property.price_rent ? null : <p>{thousandsSeparators(property.price_rent)} €</p>}
							{!property.price_sale ? null : <p>{thousandsSeparators(property.price_sale)} €</p>}
							{!property.price_rent ? null : <p>Inchiriere</p>}
							{!property.price_sale ? null : <p>Vanzare</p>}
						</div>
						<div className="property-details">
							<h4>{property.city}</h4>
							<p>{property.zone}</p>
						</div>
						<div className="more-details">
							<div>
								<div className="icon sqm"></div>
								{property.surface_useable ? <p>{property.surface_useable}<sup>m2</sup></p> : <p>{property.surface_land}<sup>m2</sup></p>}
							</div>
							{/* Show icons for houses and apartments */}
							{property.property_type === 1 || property.property_type === 3 ? <div>
								<div className="icon bed"></div>
								<p>{property.rooms}</p>
								<i className="fas fa-bath"></i>
								<p>{property.bathrooms}</p>
							</div> : null}
						</div>
					</div>
				})}
			</div>
		</div>}
		<Footer />
		<ScrollButton />
	</>
}


export default SingleProperty