import React, { useContext, useState } from 'react'
import { PropertyTypeSelected, SearchFilterDataContext } from './Context'
import { ButtonOutlined } from './Buttons'
import CarouselCard from './CaruselCard'
import Card from './Card'


const propertyType = ['Toate', 'Case', 'Birouri', 'Apartamente', 'Spatiu Comercial', 'Spatiu Industrial', 'Teren']


const AdvertisedProperties = ({ advertisedProperties, props }) => {

	const { setPropertyTypeSelected } = useContext(PropertyTypeSelected)
	const { setSearchFilter } = useContext(SearchFilterDataContext)
	const [setErrors] = useState()

	const handleChangeProperty = (event) => {
		setSearchFilter('')
		switch (event.target.value) {
			case "Toate":
				setPropertyTypeSelected('properties')
				break
			case "Case":
				setPropertyTypeSelected('houses')
				break
			case "Birouri":
				setPropertyTypeSelected('offices')
				break
			case "Apartamente":
				setPropertyTypeSelected('apartments')
				break
			case "Spatiu Comercial":
				setPropertyTypeSelected('commercial-spaces')
				break
			case "Spatiu Industrial":
				setPropertyTypeSelected('industrial-spaces')
				break
			case "Teren":
				setPropertyTypeSelected('lands')
				break
			default:
				setErrors('Nu a fost selectata nicio proprietate!')
		}
		if (event.target.value) {
			props.history.push('/properties')
		}
	}


	return <div className="properties-section">
		<h2>Proprietati</h2>
		<div className="is-mobile margin-bottom-4 dropdown">
			<select className="select" onChange={event => handleChangeProperty(event)}>
				<option disabled hidden selected>Alege tipul proprietatii</option>
				<option disabled></option>
				{propertyType.map((property, i) => <option key={i} value={property}>{property}</option>)}
			</select>
			<i class="fas fa-chevron-down select-arrow"></i>
		</div>
		<ul className="is-desktop">
			<li onClick={event => {
				setSearchFilter('')
				setPropertyTypeSelected('properties')
				props.history.push('/properties')
			}}><ButtonOutlined buttonTitle="Toate"></ButtonOutlined></li>
			<li onClick={event => {
				setSearchFilter('')
				setPropertyTypeSelected('houses')
				props.history.push('/properties')
			}}><ButtonOutlined buttonTitle="Case"></ButtonOutlined></li>
			<li onClick={event => {
				setSearchFilter('')
				setPropertyTypeSelected('offices')
				props.history.push('/properties')
			}}><ButtonOutlined buttonTitle="Birouri"></ButtonOutlined></li>
			<li onClick={event => {
				setSearchFilter('')
				setPropertyTypeSelected('apartments')
				props.history.push('/properties')
			}}><ButtonOutlined buttonTitle="Apartamente"></ButtonOutlined></li>
			<li onClick={event => {
				setSearchFilter('')
				setPropertyTypeSelected('commercial-spaces')
				props.history.push('/properties')
			}}><ButtonOutlined buttonTitle="Spatiu comercial"></ButtonOutlined></li>
			<li onClick={event => {
				setSearchFilter('')
				setPropertyTypeSelected('industrial-spaces')
				props.history.push('/properties')
			}}><ButtonOutlined buttonTitle="Spatiu industrial"></ButtonOutlined></li>
			<li onClick={event => {
				setSearchFilter('')
				setPropertyTypeSelected('lands')
				props.history.push('/properties')
			}}><ButtonOutlined buttonTitle="Teren"></ButtonOutlined></li>
		</ul>

		{/* Mobile layout */}
		<div className="is-mobile">
			<CarouselCard advertisedProperties={advertisedProperties} />
		</div>

		{/* Desktop layout */}
		<div className="properties-wrapper">
			<Card properties={advertisedProperties} />
		</div>
	</div>
}

export default AdvertisedProperties