import React from 'react'
import { makeStyles } from '@material-ui/core/styles'
import Button from '@material-ui/core/Button'



const useStyles = makeStyles((theme) => ({
	color: {
		backgroundColor: "red",
		paddingTop: 5,
		paddingBottom: 5,
		color: "white",
		'&:hover': {
			backgroundColor: "red",
		},
	},
	outlined: {
		backgroundColor: "rgba(255, 255, 255, 0.45)",
		color: "black",
	}
}))


export function ButtonContained({ buttonTitle }) {
	const classes = useStyles()

	return (
		<div>
			<Button variant="contained" color="inherit" className={classes.color}>
				{buttonTitle}
			</Button>
		</div>
	)
}


export function ButtonOutlined({ buttonTitle }) {
	const classes = useStyles()

	return (
		<div>
			<Button variant="outlined" color="inherit" className={classes.outlined}>
				{buttonTitle}
			</Button>
		</div>
	)
}
