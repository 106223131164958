import React, { useState, useContext, useEffect, useRef } from 'react'
import { Link } from 'react-router-dom'

import logo from '../images/logo.png'
import { SearchFilterDataContext } from './Context'

import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@material-ui/icons/KeyboardArrowUp';


const Nav = (props) => {

	const [isMenuOpen, setIsMenuOpen] = useState(false)
	const [showDropdown, setShowDropdown] = useState(false)
	const [activePage, seActivePage] = useState('/')
	const { setSearchFilter } = useContext(SearchFilterDataContext)
	const mobileMenu = useRef()
	const clientsDropdown = useRef()

	useEffect(() => {
		seActivePage(props.props.location.pathname)
	}, [])

	const useOutsideClick = (ref, callback) => {
		const handleClick = e => {
			if (ref.current && !ref.current.contains(e.target)){
				callback()
			}		
		}

		useEffect(() => {
			document.addEventListener('click', handleClick)

			return () => {
				document.removeEventListener('click', handleClick)
			}
		})
	}
	useOutsideClick(clientsDropdown, () => {
		if (showDropdown) setShowDropdown(!showDropdown)
	})
	useOutsideClick(mobileMenu, () => {
		if (isMenuOpen) setIsMenuOpen(!isMenuOpen)
	})



	return <><div className="nav-container">
		<div className="container-max-w relative">
			<div className="top-half">
				<div className="top-content">
					<Link to="//linkedin.com/in/aris-stan-109079131/" target="_blank"><i className="fab fa-linkedin-in"></i></Link>
					<p>/</p>
					<Link to="//facebook.com/JustAdministrare/" target="_blank"><i className="fab fa-facebook-f"></i></Link>
				</div>
			</div>

			<div className="logo-container absolute">
				<Link to="/"><div
					onClick={event => {
						setSearchFilter('')
					}}
					className="logo" style={{ backgroundImage: `url(${logo})` }}></div></Link>
			</div>
		</div>
		<div className="full-screen-nav">
			<div className="bottom-half container-max-w">
				<div className="toggle-button" href="#" onClick={() => setIsMenuOpen(!isMenuOpen)}>
					<span className={isMenuOpen ? 'bar open' : 'bar'}></span>
					<span className={isMenuOpen ? 'bar open' : 'bar'}></span>
					<span className={isMenuOpen ? 'bar open' : 'bar'}></span>
				</div>
				<div className="nav-dropdown">
					<div className="nav-clients-link" onClick={() => setShowDropdown(!showDropdown)}>
						<h4>Clienti</h4>
						{!showDropdown && <KeyboardArrowDownIcon />}
						{showDropdown && <KeyboardArrowUpIcon />}
					</div>
					{/* Clients Dropdown */}
					<div ref={clientsDropdown} id='clientsDropdown' className={showDropdown ? "show-dropdown" : "hide-dropdown"}>
						<Link className="link-drop" to="/sellers" style={{ textDecoration: 'none' }} ><h4 >Vanzatori</h4></Link>
						<Link className="link-drop" to="/buyers" style={{ textDecoration: 'none' }} ><h4 >Cumparatori</h4></Link>
						<Link className="link-drop" to="/landlords" style={{ textDecoration: 'none' }} ><h4 >Proprietari</h4></Link>
						<Link className="link-drop" to="/tenants" style={{ textDecoration: 'none' }} ><h4 >Locatari</h4></Link>
					</div>
				</div>
				<Link to="/properties" style={{ textDecoration: 'none' }} className={activePage === '/properties' ? "active-page" : "nav-link"} ><h4>Proprietati</h4></Link>
				<Link to="/about" style={{ textDecoration: 'none' }} className={activePage === '/about' ? "active-page" : "nav-link"}><h4>Despre noi</h4></Link>
				<Link to="/contact" style={{ textDecoration: 'none' }} className={activePage === '/contact' ? "active-page" : "nav-link"} ><h4>Contact</h4></Link>
				<Link to="//justadministrare.ro/" target="_blank" style={{ textDecoration: 'none' }} className="nav-link" ><h4>Administrare</h4></Link>
			</div>
		</div>
	</div>
		{/* Mobile Hamburger */}
		<div ref={mobileMenu} id='mobileMenu' className={isMenuOpen ? "is-mobile-menu-open" : "is-mobile-menu"}>
			<Link to="/properties" style={{ textDecoration: 'none' }}><h4 className={activePage === '/properties' ? "active-page-mobile" : ""}>Proprietati</h4></Link>
			<Link to="/sellers" style={{ textDecoration: 'none' }}><h4 className={activePage === '/sellers' ? "active-page-mobile" : ""}>Vanzatori</h4></Link>
			<Link to="/buyers" style={{ textDecoration: 'none' }}><h4 className={activePage === '/buyers' ? "active-page-mobile" : ""}>Cumparatori</h4></Link>
			<Link to="/landlords" style={{ textDecoration: 'none' }}><h4 className={activePage === '/landlords' ? "active-page-mobile" : ""}>Proprietari</h4></Link>
			<Link to="/tenants" style={{ textDecoration: 'none' }}><h4 className={activePage === '/tenants' ? "active-page-mobile" : ""}>Locatari</h4></Link>
			<Link to="/about" style={{ textDecoration: 'none' }}><h4 className={activePage === '/about' ? "active-page-mobile" : ""}>Despre noi</h4></Link>
			<Link to="/contact" style={{ textDecoration: 'none' }}><h4 className={activePage === '/contact' ? "active-page-mobile" : ""}>Contact</h4></Link>
			<Link to="//justadministrare.ro/" target="_blank" style={{ textDecoration: 'none' }} ><h4>Administrare</h4></Link>
		</div>
	</>
}

export default Nav